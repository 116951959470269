import gql from 'graphql-tag'

export const GetNoticeboardHistoryByNoticeboardObjectIdGQL = gql`
  query ($noticeBoardObjectId: String, $filter: OpsNoticeBoardFilterInput) {
    getNoticeboardHistoryByNoticeboardObjectId(
      noticeBoardObjectId: $noticeBoardObjectId
      filter: $filter
    ) {
      total
      noticeBoardHistories {
        id
        change
        comment
        createdAt
        createdBy {
          email
          company
        }
      }
    }
  }
`
