import gql from 'graphql-tag'

export const GetSummaryUnfulfilledDeliveriesForDateGQL = gql`
  query ($date: String!) {
    getSummaryUnfulfilledDeliveriesForDate(date: $date) {
      users {
        user {
          id
          uid
          email
          company
        }
        pickupCount {
          hour
          total
        }
      }
    }
  }
`
