import gql from 'graphql-tag'

export const GetOrdersGQL = gql`
  query ($filter: OrderFilter!) {
    getOrders(filter: $filter) {
      orders {
        objectId
        code
        routeId
        pickupTime
        status
        delivery {
          type
          price
        }
        user {
          company
          email
        }
        waypoints {
          address1
          address2
          name
          items {
            customId
          }
        }
        deliveries {
          objectId
          status
          code
          premiumDeliveries {
            id
          }
          driverInfo {
            name
          }
          delivery {
            provider
            deliveryId
          }
        }
      }
      totalOrders
      totalCompletedOrders
      totalOngoingOrders
      totalPendingOrders
      totalCancelledOrders
      totalFailedOrders
      merchants {
        id
        uid
        email
        phone
        company
      }
      providers
    }
  }
`
