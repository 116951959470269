import gql from 'graphql-tag'

export const ReraceDeliveryByObjectIdGQL = gql`
  mutation (
    $objectId: String!
    $uncancelDeliveryObjectId: [String]
    $racingProviders: [String]
  ) {
    reraceDeliveryByObjectId(
      objectId: $objectId
      uncancelDeliveryObjectId: $uncancelDeliveryObjectId
      racingProviders: $racingProviders
    ) {
      warningMessages
    }
  }
`
