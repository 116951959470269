import _ from 'lodash'

import { NODE_ENV } from '../services/Type'
import 'dotenv/config'

const defaultConfig = {
  FIREBASE: {
    API_KEY: process.env.NUXT_ENV_FIREBASE_API_KEY!,
    AUTH_DOMAIN: '',
    PROJECT_ID: '',
    STORAGE_BUCKET: '',
    MESSAGE_SENDER_ID: '',
    APP_ID: '',
    DATABASE_URL: '',
  },
  GOOGLE_MAP: {
    API_KEY: process.env.NUXT_ENV_GOOGLE_MAP_API_KEY!,
  },
  SENTRY: {
    DSN: process.env.NUXT_ENV_SENTRY_DSN!,
    ENV: process.env.NUXT_ENV_NODE_ENV!,
  },
  RIDA: {
    BACKEND_URL: '',
  },
  DRIVER_APP: {
    BACKEND_URL: '',
  },
}

export const config = {
  [NODE_ENV.DEVELOPMENT]: _.merge({}, defaultConfig, {
    FIREBASE: {
      AUTH_DOMAIN: 'rida-prototype.firebaseapp.com',
      PROJECT_ID: 'rida-prototype',
      STORAGE_BUCKET: 'rida-prototype.appspot.com',
      MESSAGE_SENDER_ID: '296239234862',
      APP_ID: '1:296239234862:web:ddd3e78fe09aa6aff644bf',
      DATABASE_URL: 'https://rida-prototype.firebaseio.com',
    },
    RIDA: {
      BACKEND_URL: 'http://localhost:3100/graphql',
    },
    DRIVER_APP: {
      BACKEND_URL: 'https://driver-app-admin-staging.rida.ai',
    },
  }),
  [NODE_ENV.TEST]: _.merge({}, defaultConfig),
  [NODE_ENV.STAGING]: _.merge({}, defaultConfig, {
    FIREBASE: {
      AUTH_DOMAIN: 'rida-prototype.firebaseapp.com',
      PROJECT_ID: 'rida-prototype',
      STORAGE_BUCKET: 'rida-prototype.appspot.com',
      MESSAGE_SENDER_ID: '296239234862',
      APP_ID: '1:296239234862:web:ddd3e78fe09aa6aff644bf',
      DATABASE_URL: 'https://rida-prototype.firebaseio.com',
    },
    RIDA: {
      BACKEND_URL: 'https://admin-graphql-staging.rida.ai/graphql',
    },
    DRIVER_APP: {
      BACKEND_URL: 'https://driver-app-admin-staging.rida.ai',
    },
  }),
  [NODE_ENV.PRODUCTION]: _.merge({}, defaultConfig, {
    FIREBASE: {
      AUTH_DOMAIN: 'rida-prod.firebaseapp.com',
      PROJECT_ID: 'rida-prod',
      STORAGE_BUCKET: 'rida-prod.appspot.com',
      MESSAGE_SENDER_ID: '859604450095',
      APP_ID: '1:859604450095:web:4d18bda1872084f4ab7fda',
      DATABASE_URL: 'https://rida-prod.firebaseio.com',
    },
    RIDA: {
      BACKEND_URL: 'https://admin-graphql.rida.ai/graphql',
    },
    DRIVER_APP: {
      BACKEND_URL: 'https://driver-app-admin.rida.ai',
    },
  }),
}
