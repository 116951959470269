import gql from 'graphql-tag'

export const GetAvailableOpsNoticeBoardForDateGQL = gql`
  query ($filter: OpsNoticeBoardFilterInput) {
    getAvailableOpsNoticeBoardForDate(filter: $filter) {
      total
      noticeBoards {
        objectId
        title
        message
        valid {
          from
          to
          daysOfWeek
        }
        priority
        createdAt
        createdBy {
          email
          company
        }
      }
    }
  }
`
