


































































































































































import { Component, mixins, Watch } from 'nuxt-property-decorator'
import SvgClose from '~/assets/svg/close.svg'
import UtilityMixins from '~/mixins/Utility'
import { NotificationService, NUXT_EVENT, UtilityService } from '~/services'
import _ from 'lodash'

@Component({
  components: {
    SvgClose,
  },
})
export default class SideMenu extends mixins(UtilityMixins) {
  public envConfig = UtilityService.getConfig()
  public drawer = false
  public mini = false
  public userSetCollapse = false
  public navItems = [
    {
      label: 'Orders',
      path: 'orders',
      key: ['order', 'delivery'],
      icon: 'mdi-home-outline',
    },
    {
      label: 'Routes',
      path: 'routes',
      key: ['routes'],
      icon: 'mdi-source-pull',
    },
    {
      label: 'Stacking Routes',
      path: 'stackingRoutes',
      key: ['stackingRoutes'],
      icon: 'mdi-chart-bar-stacked',
    },
    {
      label: 'Merchants',
      path: 'merchants',
      key: ['merchant'],
      icon: 'mdi-briefcase-outline',
    },
    {
      label: 'Incomplete Deliveries',
      path: 'deliveries/incomplete',
      key: ['deliveries/incomplete'],
      icon: 'mdi-monitor-speaker-off',
      count: 0,
    },
    {
      label: 'Command',
      path: 'command',
      key: ['command'],
      icon: 'mdi-cog-outline',
    },
    {
      label: 'Pandago Address',
      path: 'addresses',
      key: ['address'],
      icon: 'mdi-home-city-outline',
    },
    {
      label: 'Notice Boards',
      path: 'opsNoticeBoards',
      key: ['opsNoticeBoard'],
      icon: 'mdi-bulletin-board',
    },
  ]

  @Watch('$isTablet', { immediate: true })
  public onIsTabletChange(val: boolean) {
    if (!this.userSetCollapse) {
      this.mini = val
    }
  }

  @Watch('loggedInState')
  public async onLoggedInStateChange(loggedInState: boolean) {
    if (!loggedInState) {
      this.$accessor.showSplashScreen()
      await this.$router.replace('/login')
    }
  }

  public get loggedInState() {
    return this.$accessor.auth.isLoggedIn
  }

  public mounted() {
    this.$nuxt.$on(NUXT_EVENT.DRAWER_OPEN, () => {
      this.drawer = true
    })
    this.getSidebarNotifications()
  }

  public beforeDestroy() {
    this.$nuxt.$off(NUXT_EVENT.DRAWER_OPEN)
  }

  public async logout() {
    this.$accessor.showSplashScreen()
    await this.$accessor.logout()
  }

  public isNavActive(keys: string[]) {
    return _.some(keys, key => _.includes(this.$route.path, key))
  }

  public collapse() {
    this.mini = !this.mini
    this.userSetCollapse = true
  }

  public async getSidebarNotifications() {
    const { getSidebarNotification } =
      await NotificationService.getSidebarNotification()
    const nav = _.find(this.navItems, v => v.path === 'deliveries/incomplete')

    if (nav) {
      nav.count = getSidebarNotification.totalIncompleteDeliveries
    }
  }
}
