import gql from 'graphql-tag'

export const GetUserConfigHistoryGQL = gql`
  query ($userObjectId: String!, $filter: UserConfigHistoryFilter) {
    getUserConfigHistory(userObjectId: $userObjectId, filter: $filter) {
      histories {
        change
        createdAt
        createdBy {
          company
          email
        }
      }
      totalHistory
      user {
        company
        email
      }
    }
  }
`
