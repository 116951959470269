import gql from 'graphql-tag'

export const UpdateProviderDetailsForDeliveryGQL = gql`
  mutation (
    $objectId: String!
    $provider: String!
    $deliveryId: String!
    $cost: String!
    $confirmation: Boolean!
    $finalCost: String
    $uncancelDeliveryObjectId: [String]
  ) {
    updateProviderDetailsForDelivery(
      objectId: $objectId
      provider: $provider
      deliveryId: $deliveryId
      cost: $cost
      confirmation: $confirmation
      finalCost: $finalCost
      uncancelDeliveryObjectId: $uncancelDeliveryObjectId
    ) {
      warningMessages
    }
  }
`
