import gql from 'graphql-tag'

export const Download3PLOrderFileForRouteGQL = gql`
  mutation ($objectId: String!) {
    download3PLOrderFileForRoute(objectId: $objectId) {
      content
      routeId
    }
  }
`
