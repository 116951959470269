import gql from 'graphql-tag'

export const ChooseRacingProvidersForDeliveryByObjectIdGQL = gql`
  mutation ($objectId: String!, $selectedProviders: [String]) {
    chooseRacingProvidersForDeliveryByObjectId(
      objectId: $objectId
      selectedProviders: $selectedProviders
    )
  }
`
