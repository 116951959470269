import gql from 'graphql-tag'

export const UpdateDeliveryDetailByObjectIdGQL = gql`
  mutation (
    $objectId: String!
    $stops: [UpdateDeliveryDetailsByObjectIdStopRequest]
  ) {
    updateDeliveryDetailsByObjectId(objectId: $objectId, stops: $stops)
  }
`
