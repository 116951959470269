import { ApolloError, isApolloError } from 'apollo-client'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'

import { config } from '../config'
import { NODE_ENV } from './Type'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)

class Utility {
  public static getNodeEnv() {
    const defaultEnv = NODE_ENV.DEVELOPMENT
    const currentEnv = process.env.NUXT_ENV_NODE_ENV

    return _.values(NODE_ENV).includes(currentEnv as NODE_ENV)
      ? (currentEnv as NODE_ENV)
      : defaultEnv
  }

  public static getConfig() {
    const env = this.getNodeEnv()
    return config[env]
  }

  public static isProduction() {
    return this.getNodeEnv() === NODE_ENV.PRODUCTION
  }

  public static isStaging() {
    return this.getNodeEnv() === NODE_ENV.STAGING
  }

  public static notEmpty<TValue>(
    value: TValue | null | undefined,
  ): value is TValue {
    return (
      value !== null &&
      value !== undefined &&
      value !== ('' as unknown as TValue)
    )
  }

  public static isAxiosError<T>(error: Error): error is AxiosError<T> {
    return (error as AxiosError).isAxiosError !== undefined
  }

  public static isApolloError(error: Error): error is ApolloError {
    return isApolloError(error)
  }

  public static trimSide<T>(str: T) {
    if (typeof str === 'string') {
      return _.chain(str).trimStart().trimEnd().value()
    }

    return str
  }

  public static isNumber<T>(value: T) {
    if (_.isString(value)) {
      return /^-?\d+\.?\d*$/.test(value)
    }

    return _.isFinite(value)
  }

  public static isEmail(s: string) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      s,
    )
  }

  public static getNearestTimeByStep(
    timeChosen = dayjs(),
    step = 30,
    isForward = true,
  ) {
    const pivotTime = dayjs(timeChosen)
    if (isForward) {
      return pivotTime
        .minute(Math.ceil(pivotTime.minute() / step) * step)
        .startOf('minute')
    }

    return pivotTime
      .minute(Math.floor(pivotTime.minute() / step) * step)
      .startOf('minute')
  }

  // see https://stackoverflow.com/questions/34993292/how-to-save-xlsx-data-to-file-as-a-blob
  public static stringToArrayBuffer(str: string) {
    const binary = atob(str)
    const buffer = new ArrayBuffer(binary.length)
    const view = new Uint8Array(buffer)
    _.each(str, (_s, index) => {
      view[index] = binary.charCodeAt(index) & 0xff
    })

    return buffer
  }

  public static getHumanReadableDuration(diff: number) {
    const hourInMins = 60
    const dayInMins = hourInMins * 24
    const weekInMins = dayInMins * 7
    const monthInMins = dayInMins * 30

    const months = Math.floor(diff / monthInMins)
    diff -= monthInMins * months

    const weeks = Math.floor(diff / weekInMins)
    diff -= weekInMins * weeks

    const days = Math.floor(diff / dayInMins)
    diff -= dayInMins * days

    const hours = Math.floor(diff / hourInMins)
    diff -= hourInMins * hours

    const minutes = diff

    let result = ''
    result += months ? months + 'mth' : ''
    result += weeks ? weeks + 'w' : ''
    result += days ? days + 'd' : ''
    result += hours ? hours + 'h' : ''
    result += minutes ? minutes + 'm' : ''

    return _.trim(result) || '0m'
  }
}

export { Utility as UtilityService }
